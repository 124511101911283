/****************************/
/**** BACKGROUND WEBGL ******/
/****************************/

import { Scene, PerspectiveCamera, WebGLRenderer, MeshBasicMaterial } from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import {func} from "three/addons/nodes/code/FunctionNode";

let scene = new Scene();
let camera = new PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 100);
let renderer = new WebGLRenderer({antialias: true});

renderer.setPixelRatio(window.devicePixelRatio);
renderer.setSize(window.innerWidth, window.innerHeight);
renderer.setClearColor(0xffffff);
renderer.domElement.style.position = 'fixed';
document.body.appendChild(renderer.domElement);

let loader = new GLTFLoader();
let obj3D;

loader.load('./assets/3d/logo.glb', function (gltf) {
    obj3D = gltf.scene;
    obj3D.scale.set(1, 1, 1);
    obj3D.position.set(0, 0, 0);
    obj3D.rotation.y = 0.2;
    obj3D.rotation.x = -0.2;

    obj3D.traverse((child) => {
        if (child.isMesh) {
            let wireframeMaterial = new MeshBasicMaterial({
                wireframe: true,
                color: 0x000000,
                opacity: 0.085,
                transparent: true
            });

            child.material = wireframeMaterial;
        }
    });

    scene.add(obj3D);

    if (window.innerWidth < 500) {
        camera.position.z = 7;
    } else {
        camera.position.z = 5;
    }
    animate();

});

window.addEventListener('mousemove', handleMouseMove);

let mouseX = 0;
let mouseY = 0;
let clientX = 0;
let clientY = 0;
let currentRotationY = 0;
let currentRotationX = 0;
const windowHalfX = window.innerWidth / 2;
const windowHalfY = window.innerHeight / 2;

function handleMouseMove(event) {
    mouseX = (event.clientX - windowHalfX) / windowHalfX;
    mouseY = (event.clientY - windowHalfY) / windowHalfY;
    clientX = event.clientX;
    clientY = event.clientY;
}

const lerpFactor = 0.05;
let rotateY = 0.002;
let rotateX = 0.001;

function animate() {
    requestAnimationFrame(animate);

    if (obj3D) {
        // Rotation douce autour de l'axe Y
        obj3D.rotation.y += rotateY;

        // Petite inclinaison autour de l'axe X pour donner une impression de flottement
        obj3D.rotation.x += Math.sin(obj3D.rotation.y) * rotateX;
    }

    renderer.render(scene, camera);
}

window.addEventListener('resize', function () {
    let width = window.innerWidth;
    let height = window.innerHeight;
    renderer.setSize(width, height);
    camera.aspect = width / height;
    camera.updateProjectionMatrix();
});

// DARK MODE

const $darkLightRound = document.querySelector('#darkLightRound');
const $mojiElement = document.querySelector('.moji');
const mojis = ["🫢", "🙊", "🫠","🥹","🤓", "🤭", "🙈"];
let currentIndex = 1;  // Commencez par le deuxième emoji
let darkLightRound_size = 0;

function getNextEmoji() {
    const emoji = mojis[currentIndex];
    currentIndex = (currentIndex + 1) % mojis.length;
    return emoji;
}

function captureAndPosition(darkLightRound_size) {

    darkLightRound_size = darkLightRound_size / 2;

    $darkLightRound.style.left = clientX - darkLightRound_size + 'px';
    $darkLightRound.style.top = clientY - darkLightRound_size + 'px';

}

const setDarkMode = (active = false, init = false) => {

    const wrapper = document.querySelector(":root");

    if (!init) {
        if (window.innerWidth > window.innerHeight) {
            darkLightRound_size = (window.innerWidth * 3) + 'px';
        } else {
            darkLightRound_size = (window.innerHeight * 3) + 'px';
        }

        $darkLightRound.style.height = darkLightRound_size;
        $darkLightRound.style.width = darkLightRound_size;

        captureAndPosition(parseInt(darkLightRound_size)); // On positionne le "rideau"

        document.documentElement.classList.add("hidemoji");

        setTimeout(function(){
            $darkLightRound.style.backdropFilter = "invert()";
            $darkLightRound.style.webkitBackdropFilter = "invert()";
        },200);



        if (active) {
            document.documentElement.classList.remove("sun");
            TweenMax.to($darkLightRound, 1, {
                scale: 1,
                ease: Power3.easeInOut,
                onCompleteScope: $darkLightRound,
                onComplete: function () {
                    wrapper.setAttribute("data-theme", "dark");
                    localStorage.setItem("theme", "dark");
                    TweenMax.to($darkLightRound, 0, {scale: 0});
                    $mojiElement.textContent = getNextEmoji();
                    document.documentElement.classList.remove("hidemoji");
                }
            });
        } else {
            document.documentElement.classList.add("sun");
            TweenMax.to($darkLightRound, 1, {
                scale: 1,
                ease: Power3.easeInOut,
                onCompleteScope: $darkLightRound,
                onComplete: function () {
                    wrapper.setAttribute("data-theme", "light");
                    localStorage.setItem("theme", "light");
                    TweenMax.to($darkLightRound, 0, {scale: 0});
                    $mojiElement.textContent = getNextEmoji();
                    document.documentElement.classList.remove("hidemoji");
                }
            });
        }

    } else {
        if (active) {
            document.documentElement.classList.remove("sun");
            wrapper.setAttribute("data-theme", "dark");
            localStorage.setItem("theme", "dark");
        } else {
            document.documentElement.classList.add("sun");
            wrapper.setAttribute("data-theme", "light");
            localStorage.setItem("theme", "light");
        }
    }

};

const toggleDarkMode = () => {
    const theme = document.querySelector(":root").getAttribute("data-theme");
    setDarkMode(theme === "light");
};

const initDarkMode = () => {
    const query = window.matchMedia("(prefers-color-scheme: dark)");
    const themePreference = localStorage.getItem("theme");

    let active = query.matches;

    if (themePreference === "dark") {
        active = true;
    }
    if (themePreference === "light") {
        active = false;
    }

    setDarkMode(active, true);

    query.addListener(e => setDarkMode(e.matches));

    const toggleButton = document.querySelector(".js__dark-mode-toggle");
    toggleButton.addEventListener("click", toggleDarkMode);
};

initDarkMode();

// CHANGEMENT DE MOTS

const words = ["bichonner", "dorloter", "cajoler", "optimiser", "fignoler", "peaufiner"];
let currentWordIndex = 0;

const target = document.getElementById("changing-word");

function shuffleString(string, desiredLength) {
    let array = string.split('');

    while (array.length < desiredLength) {
        array.push(String.fromCharCode(97 + Math.floor(Math.random() * 26))); // Ajoute des lettres aléatoires
    }

    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }

    return array.join('');
}

function changeWord() {
    const currentWord = words[currentWordIndex];
    const nextWord = words[(currentWordIndex + 1) % words.length];
    const maxLength = Math.max(currentWord.length, nextWord.length);
    let animationFrame;
    let counter = 0;

    function animate() {
        if (counter <= maxLength) {
            const length = currentWord.length + (counter * (nextWord.length - currentWord.length)) / maxLength;
            target.textContent = shuffleString(nextWord, Math.round(length));
            counter++;
            animationFrame = requestAnimationFrame(animate);
        } else {
            cancelAnimationFrame(animationFrame);
            target.textContent = nextWord;
            currentWordIndex = (currentWordIndex + 1) % words.length;
            $mojiElement.textContent = getNextEmoji();
        }
    }

    animate();
}

setInterval(changeWord, 4000); // changer le mot toutes les 4 secondes

function showCursor(){
    if (!isMobile()) document.documentElement.removeAttribute('data-mobile');
}

// Créez une instance de l'observateur avec une fonction de rappel
const observer = new MutationObserver(mutationsList => {
    for (let mutation of mutationsList) {
        if (mutation.type === 'childList') {
            for (let removedNode of mutation.removedNodes) {
                if (removedNode.classList && removedNode.classList.contains('calendly-overlay')) {
                    showCursor(); // Si l'élément .calendly-overlay est supprimé, exécutez showCursor()
                }
            }
        }
    }
});

// Configuration de l'observateur
const config = {
    childList: true,
    subtree: true
};

// Démarrer l'observation du document avec la configuration donnée
observer.observe(document.body, config);

if (!isMobile()) {

    document.documentElement.removeAttribute('data-mobile');
    
    // CURSOR

    const $bigBall = document.querySelector('.cursor__ball--big');
    const $bigBallSVG = document.querySelector('.cursor__ball--big svg');
    const $cursorBall = document.querySelector('.cursor__ball');
    const $hoverables_hide = document.querySelectorAll('.hoverable_hide');
    const $hoverables_light = document.querySelectorAll('.hoverable_light');
    const button = document.querySelector("#contact");

    let isHovering = false;

    document.body.addEventListener('mousemove', (e) => {
        if (!isHovering) {
            // Si nous ne survolons pas un élément hoverable, déplacez le grand cercle normalement
            TweenMax.to($bigBall, .4, {
                x: e.pageX - 25,
                y: e.pageY - 25,
                ease: Back.easeOut
            });
        }
    });

    $hoverables_hide.forEach((hoverable) => {
        hoverable.addEventListener('mouseenter', () => {
            isHovering = true;

            const rect = button.getBoundingClientRect();
            const centerX = rect.left + window.scrollX + (rect.width / 2) - 25;
            const centerY = rect.top + window.scrollY + (rect.height / 2) - 25;

            TweenMax.to($bigBall, .3, {
                scale: .5,
                x: centerX,
                y: centerY
            });
            $cursorBall.classList.add("hide");
        });

        hoverable.addEventListener('mouseleave', () => {
            isHovering = false;
            TweenMax.to($bigBall, .3, {
                scale: 1
            });
            $cursorBall.classList.remove("hide");
        });
    });

    $hoverables_light.forEach((hoverable) => {
        hoverable.addEventListener('mouseenter', () => {

            const rect = button.getBoundingClientRect();
            const centerX = rect.left + window.scrollX + (rect.width / 2) - 25;
            const centerY = rect.top + window.scrollY + (rect.height / 2) - 25;

            TweenMax.to($bigBallSVG, .3, {
                scale: 1.5,
                ease: Back.easeOut
            });
            $cursorBall.classList.add("light");
        });

        hoverable.addEventListener('mouseleave', () => {
            isHovering = false;
            TweenMax.to($bigBallSVG, .3, {
                scale: 1
            });
            $cursorBall.classList.remove("light");
        });

    });


// MAGNETIC BUTTON EFFECT

//Settings
    let interval = null;
    let xpos = 0;
    let ypos = 0;
    let scale = 1;
    let maxScale = 1.05;
    let accel = 0.03;

//Add event listeners
    const buttons = document.querySelectorAll(".button");
    if (!isMobile())
        document.addEventListener("mousemove", throttle(onMouseMove, 10));

//Set active button on hover
    let activeButton = null;
    buttons.forEach((button) => {
        //On hover
        button.addEventListener("mouseover", () => {
            button.classList.add("magnet");
            activeButton = button;
        });

        //On leave
        button.addEventListener("mouseleave", () => {
            activeButton = null;
            xpos = 0;
            ypos = 0;
            scale = 1;
            clearInterval(interval);
            button.style.transform = "";
            button.classList.remove("magnet");
        });
    });

//On mouse move
    function onMouseMove(e) {
        if (!activeButton) return;

        // Set X,Y
        let mouseX = e.pageX;
        let mouseY = e.pageY;

        //Get coordinates
        let xdest =
            (mouseX - (activeButton.offsetLeft + activeButton.offsetWidth / 2)) / 4;
        let ydest =
            (mouseY - (activeButton.offsetTop + activeButton.offsetHeight / 2)) / 4;

        //Animate button
        clearInterval(interval);
        interval = setInterval(function () {
            xpos += (xdest - xpos) * accel;
            ypos += (ydest - ypos) * accel;
            scale += (maxScale - scale) * accel;
            activeButton.style.transform = `translate3d(${xpos}px, ${ypos}px, 0) scale(${scale})`;
            if (
                Math.abs(xdest - xpos) < 1 &&
                Math.abs(ydest - ypos) < 1 &&
                Math.abs(maxScale - scale) < 1
            )
                clearInterval(interval);
        }, 1);
    }

//Throttle mouse event function
    function throttle(func, limit) {
        let inThrottle;
        return function () {
            const args = arguments;
            const context = this;
            if (!inThrottle) {
                func.apply(context, args);
                inThrottle = true;
                setTimeout(() => (inThrottle = false), limit);
            }
        };
    }

}

//Detect mobile devices
function isMobile() {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
    );
}

// HEURE GREETING

// Obtenir l'heure actuelle
let currentHour = new Date().getHours();
// Obtenir l'élément où la salutation sera affichée
let greetingElement = document.getElementById('contact');
// Décider de la salutation basée sur l'heure
if (currentHour >= 18 || currentHour < 6) {
    greetingElement.setAttribute('hover-text', 'Bonsoir 👋️');
} else {
    greetingElement.setAttribute('hover-text', 'Bonjour ✌️');
}
